body,
html {
  margin: 1em;
}

h1,
h2 {
  color: #666;
}

li {
  margin-top: .5em;
}

.post {
  border: 2px solid #999;
  font-size: 1.25em;
  line-height: 1.2;
  margin-top: .5em;
  padding: 1em;
  max-width: 38em;
  width: 100%;
}

.back,
.about {
  margin-top: .5em
}
